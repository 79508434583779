import React from 'react'
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Header() {
  const removeOffcanvas = () => {
    $('.offcanvas').offcanvas('hide');
  }
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    const currentURL = window.location.href;
    //window.location.reload(currentURL);
  };
 


  


  return (
    <>
      {/* <Button variant="primary" onClick={handleShow} >
        check
      </Button>
      <Offcanvas show={show} onHide={handleClose} >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <li><a href="/product/ballistic-vests" > Ballistic Vest</a></li>
        </Offcanvas.Body>
      </Offcanvas> */}

      <header>
        <nav className="navbar navbar-expand-md navbar-light ">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src="/images/logo.png" alt="" className="img-fluid" width={75} height={62} loading="lazy"/>
              
            </Link>
            <button
              className="navbar-toggler ms-auto"
              type="button"
              onClick={handleShow}
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* <a
          href="/product/"
          className="offcanvas-hemburg mobile"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i className="fa-solid fa-bars" />
        </a> */}
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item active">
                  <Link className="nav-link " to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" onClick={handleShow}>
                    Our Products
                  </Link>
                </li>
                {/*  <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">Blog</a>
      </li> */}
                <li className="nav-item">

                  <Link className="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
             
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            {/* <a
          href="/product/"
          className="offcanvas-hemburg desktop"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i className="fa-solid fa-bars" />
        </a> */}
          </div>
        </nav>
      </header>



      <Offcanvas show={show} onHide={handleClose} placement='end' name='end'>
        <Offcanvas.Header closeButton>

        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="offcanvas-block">
                <h4><a href="/ballistic-protection" onClick={handleClose} >Ballistic Protection <i class="fa-solid fa-link"></i></a></h4>
                <ul className="offcanvas-list">
                  <li><a href="/ballistic-vests" onClick={handleClose}> Ballistic Vest</a></li>
                  <li><a href="/product/military-vest" onClick={handleClose}>Army Vest</a></li>
                  <li><a href="/product/tactical-vest" onClick={handleClose}>Assault vest</a></li>
                  <li><a href="/product/ballistic-flotation-vest" onClick={handleClose}>Flotation Vest</a></li>
                  <li><a href="/product/front-open-molle-webbing-vest" onClick={handleClose}>Front Open Molle Vest</a></li>
                  <li><a href="/product/gauzy-vest" onClick={handleClose}>Gauzy Vest</a></li>
                  <li><a href="/product/overt-carrier" onClick={handleClose}>Overt Carrier</a></li>
                  <li><a href="/product/general-purpose-vest" onClick={handleClose} >GPV</a> </li>
                  <li><a href="/product/k9" onClick={handleClose}>K9</a></li>
                  <li><a href="/product/quick-release-system" onClick={handleClose}>Quick Release Vest</a></li>
                  <li><a href="/product/rescuer-vest" onClick={handleClose}>Rescuer Vest</a></li>
                  <li><a href="/product/savior-vest" onClick={handleClose}>Savior Vest</a></li>
                  <li><a href="/product/torc" onClick={handleClose}>TORC</a></li>
                  <li><a href="/product/valiant-tactical-carrier" onClick={handleClose}>Valiant Tactical Carrier</a></li>
                  <li><a href="/product/vip-undervest" onClick={handleClose}>VIP Vest</a></li>
                  <li><a href="/product/plate-carrier-vest-slick-i" onClick={handleClose}>Plate Carrier Vest Slick-I</a></li>
                </ul>

                <ul className="offcanvas-list">
                  <li><a href="/ballistic-plates" onClick={handleClose}>Ballistic Plates</a></li>
                  <li><a href="/product/hard-armour-panellevel-iii" onClick={handleClose}>Hard Armour Panel (Level-III) 1.4 KG</a></li>
                  <li><a href="/product/hard-armour-panel-level-iii-2-9-kg" onClick={handleClose}>Hard Armour Panel (Level-III) 2.9 KG</a></li>
                  <li><a href="/product/hard-armour-panel-level-iii-1-8-kg" onClick={handleClose}>Hard Armour Panel (Level-III) 1.8 KG</a></li>
                  <li><a href="/product/hard-armour-panel-level-iii-3-2-kg" onClick={handleClose}>Hard Armour Panel (Level-III) 3.2 KG</a></li>
                  <li><a href="/product/hard-armour-panel-level-iv-2-6-kg" onClick={handleClose}>Hard Armour Panel (Level-IV) 2.6 KG</a></li>
                  <li><a href="/product/hard-armour-panel-level-iv-3-2-kg" onClick={handleClose}>Hard Armour Panel (Level-IV) 3.2 KG</a></li>
                </ul>

                <ul className="offcanvas-list">
                  <li><a href="/ballistic-helmet" onClick={handleClose}>Ballistic Helmet</a></li>
                  <li><a href="/product/mich-helmet" onClick={handleClose}>Mich with Acc</a></li>
                  <li><a href="/product/pasgt-helmets" onClick={handleClose}>PASGT with Acc</a></li>
                  <li><a href="/product/special-force-helmet" onClick={handleClose}>SF with Acc</a></li>
                  <li><a href="/product/mid-cut-with-acc" onClick={handleClose}>Mid-Cut with Acc</a></li>
                  <li><a href="/product/patka" onClick={handleClose}>Patka</a></li>
                  <li><a href="/product/raps-helmet" onClick={handleClose}> RAPS Helmet </a></li>

                </ul>

                <ul className="offcanvas-list">
                  <li><a href="/demining-clothing" onClick={handleClose}>Demining Clothing</a></li>
                  <li><a href="/product/de-mining-apron" onClick={handleClose}>Demining Apron</a></li>
                  <li><a href="/product/de-mining-vest" onClick={handleClose}>Demining vest</a></li>
                  <li><a href="/product/de-mining-suit" onClick={handleClose}>Demining suit</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/platform-protection" onClick={handleClose}>Platform Protection</a></li>
                  <li><a href="/product/armoured-vehicle" onClick={handleClose}>Land Protection</a></li>
                  <li><a href="/product/naval-protection" onClick={handleClose}>Naval Protection</a></li>
                  <li><a href="/product/air-protection" onClick={handleClose}>Air Protection</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/product/ballistic-folding-shield" className='unbold' onClick={handleClose}>Ballistic Folding Shield</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/product/composite-ballistic-panels" className='unbold' onClick={handleClose}>Composite Ballistic Panels</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/product/bomb-blanket" className='unbold' onClick={handleClose}>Bomb Blankets</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/product/ballistic-technical-fabric" className='unbold' onClick={handleClose} >Ballistic Technical Fabric</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/product/ballistic-shield" className='unbold' onClick={handleClose}>Ballistic Shield</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/product/spall-liner" className='unbold' onClick={handleClose}>Spall liners</a></li>
                </ul>

              </div>


            </div>
            <div className="col-lg-6">

              <div className="offcanvas-block">
                <h4><a href="/product/ballistic-helmet-acc" onClick={handleClose} >Ballistic Helmet Acc <i class="fa-solid fa-link"></i></a></h4>

                <ul className="offcanvas-list">
                  <li><a href="/product/face-guard-visor" onClick={handleClose}>Face Guard & Visor</a></li>
                  <li><a href="/product/step-in-visor" onClick={handleClose}>STEP IN Visor</a></li>
                  <li><a href="/product/ballistic-visor-nij-level-iiia" onClick={handleClose}>Ballistic Visior</a></li>
                </ul>

                <ul className="offcanvas-list">
                  <li><a href="/product/nvg-shroud" onClick={handleClose}>NVG shroud</a></li>
                  <li><a href="/product/3-hole-skeleton" onClick={handleClose}>3 Hole skeleton</a></li>
                </ul>

                <ul className="offcanvas-list">
                  <li><a href="/product/helmet-pads" onClick={handleClose}>Helmet Pads</a></li>
                  <li><a href="/product/epp-pad" onClick={handleClose}>EPP pad</a></li>
                  <li><a href="/product/liner-Pad" onClick={handleClose}>Liner Pad</a></li>
                </ul>


                <ul className="offcanvas-list">
                  <li><a href="/product/harness" onClick={handleClose}>Harness</a></li>
                  <li><a href="/product/retention-system-with-rsc-dial" onClick={handleClose}>Retention System with RSC Dial</a></li>
                  <li><a href="/product/retention-system-with-worm-dial" onClick={handleClose}>Retention System with Worm Dial</a></li>
                </ul>

                <ul className="offcanvas-list">
                  <li><a href="/product/rails-picatinny-with-adapters-bungee-cord" className='unbold' onClick={handleClose}>Side rails</a></li>
                </ul>
                <ul className="offcanvas-list">
                  <li><a href="/product/counter-weight" className='unbold' onClick={handleClose}>Counterweight</a></li>
                </ul>
              </div>
              <div className="offcanvas-block">
                <h4><a href="/tactical-gears" onClick={handleClose} >Tactical Gears <i class="fa-solid fa-link"></i></a></h4>
                <ul className="offcanvas-list">
                <li><a href="/product/tactical-trousers" className='unbold' onClick={handleClose} style={{ display: 'none' }}> Tactical Trousers</a></li>
                  <li><a href="/product/tactical-trousers" className='unbold' onClick={handleClose}> Tactical Trousers</a></li>
                  <li><a href="/product/tactical-bag-for-weapons" className='unbold' onClick={handleClose}>Tactical Bag For Weapons</a></li>
                  <li><a href="/product/tactical-uniform" className='unbold' onClick={handleClose}> Tactical Uniform</a></li>
                  <li><a href="/product/vehicle-medical-bag" className='unbold' onClick={handleClose}>Vehicle Medical Bag</a></li>
                  <li><a href="/product/first-aid-kit" className='unbold' onClick={handleClose}> First Aid Kit</a></li>
                  <li><a href="/product/ballistic-goggles" className='unbold' onClick={handleClose}> Ballistic Goggles</a></li>
                  <li><a href="/product/tactical-belt" className='unbold' onClick={handleClose}> Tactical Belt</a></li>
                  <li><a href="/product/military-boots" className='unbold' onClick={handleClose}> Military shoes</a></li>
                  <li><a href="/product/tourniquet" className='unbold' onClick={handleClose} > Tourniquet</a></li>
                  <li><a href="/product/tactical-pads" className='unbold' onClick={handleClose}> Tactical Pads</a></li>
                  <li><a href="/product/tactical-molle-pouches" className='unbold' onClick={handleClose}> Tactical Pouches</a></li>
                  <li><a href="/product/modular-gun-holster" className='unbold' onClick={handleClose}> Gun Holster </a></li>
                  <li><a href="/product/tactical-kit" className='unbold' onClick={handleClose}> Tactical Kit </a></li>
                  <li><a href="/product/military-tactical-backpack-rucksack-100-liter" className='unbold' onClick={handleClose}> Rucksack 100 Litre </a></li>
                  <li><a href="/product/military-tactical-Backpack-60-Litre" className='unbold' onClick={handleClose}> Tactical Backpack 60 Litre </a></li>
                </ul>  
               
              </div>
              <div className="offcanvas-block">
                <h4><a href="/anti-riot-gear" onClick={handleClose} >Anti-Riot Gear <i class="fa-solid fa-link"></i></a></h4>
                <ul className="offcanvas-list">
                <li><a href="/product/tourniquet" className='unbold' onClick={handleClose} style={{ display: 'none' }}> Tourniquet</a></li>
                <li><a href="/product/riot-suit" className='unbold' onClick={handleClose}> Riot Suit</a></li>
                <li><a href="/product/riot-helmet" className='unbold' onClick={handleClose}> Riot Helmet</a></li>
                <li><a href="/product/riot-shield" className='unbold' onClick={handleClose}> Riot Shield</a></li>
                </ul>  
               
              </div>
              {/* offcanvas block */}
              <div className="offcanvas-block">
                <div className='mobile-menu'>
                  <ul className='mobile-menu-list'>
                    <li><a href="https://www.indianarmour.com/about-us" onClick={handleClose}>About Us</a></li>
                    <li><a href="/blog" onClick={handleClose}>Blog</a></li>
                    <li><a href="https://www.indianarmour.com/contact-us" onClick={handleClose}>Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas-social">
            <div className="social-media d-flex align-items-center top-social">
              <span>Follow Us</span>
              <ul className="d-flex list-inline m-0">
                <li>
                  <a href="https://www.facebook.com/indianarmoursystems/" target='_blank'>
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/indian-armour-5b60a6188/" target='_blank'>
                    <i className="fa-brands fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/armour_indian" target='_blank'>
                    <i className="fa-brands fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/indian_armour_systems/" target='_blank'>
                    <i className="fa-brands fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCXTbVLwSlwaSKsQoRV2E1RA" target='_blank'>
                    <i className="fa-brands fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>








    </>
  )
}

export default Header