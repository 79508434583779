import React,{ FormEvent, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { API_URL } from "../utils/utility";
import axios from 'axios'
import { data } from "jquery";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
function Contact() {

  const [country, setCountry] = useState([])
  const API = 'https://www.indianarmour.com/indianarmourcms/APIs/Contactus/country.php';
  const fetchPost = () => {
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setCountry(res)
      })
  }
  useEffect(() => {
    fetchPost()
  }, [])




  const navigate = useNavigate();
  const [info, setInfo] = useState({
      type: "message",
      body: "",
    });

  const SaveData = async (END_POINT , body ) => {
    console.log(END_POINT, body);
    var response = await axios.post( END_POINT, body);
    console.log(response)
    return { data: response.data, message: response.message, status: response.status || response.statusCode, errors: response.errors };
  };

  const { register, handleSubmit, formState: { errors }} = useForm();
  const onSubmit = async(values) => {
    
    console.log(values)
    // this.preventDefault();
    // if (!checked) {
    //   return setInfo({
    //     body: "Please accept the terms and condition",
    //     type: "error",
    //   });
    // } else if (!checkedPrivacyPolicy) {
    //   return setInfo({
    //     body: "Please accept the Privacy Policy",
    //     type: "error",
    //   });
    // }
    // setInfo({ body: "", type: "message" });
     
  
  
  
  
  
    
    try {
      let apiRes = null;
    
  
  
        apiRes = await SaveData(`${API_URL}/APIs/Contactus/index.php`, {
          ...values 
           
        });
     console.log(apiRes.status)
      if (apiRes.status == 200 && apiRes.data[0].Status == "True") {
        setInfo({ type: "message", body: "Form Submitted Successfully!" });
      //  setsubmitdisable(true)
       
      navigate(`/Thankyou`);
      } else if(apiRes.data[0].Status == "False") {
        
        setInfo({ type: "error", body: "Something went wrong. Please try again!" });
        // throw new Error(apiRes.errors);
      }
    } catch (err) {
      setInfo({ type: "error", body: err.message });
    } 
    // finally {
    //   setApiStatus({ ...apiStatus, inProgress: false });
    // }
  
  };
 
  return (
    <>
      <Helmet>
        <title>Connect with Us | Reach Out for Inquiries and Assistance </title>
        <meta name="description" content="Get in touch with Indian Armour through our Contact Us page. Whether you have questions, feedback, or need assistance, our team is here to help. Fill out the form, and we'll respond promptly. Your connection to quality service starts here." />
        <link rel="canonical" href="https://www.indianarmour.com/contact-us" />
    
      </Helmet>
    <section
      className="inner-banner d-flex align-items-center"
      style={{ backgroundImage: 'url("/images/contact.jpg")' }}
    >
      <div className="inner-banner-content1">
        <div className="container">
          <h1 className="text-white fw-bolder">Contact Us</h1>
        </div>
      </div>
    </section>



    <section className="breadcrumb-inner pt-3  pb-5">
      <div className="container">
        <nav>
          <ol className="breadcrumb mb-0 justify-content-start">
            <li className="breadcrumb-item">
         <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Contact Us
            </li>
          </ol>
        </nav>
      </div>
    </section>
    <section className="contact-us pb-100">
      <div className="container">
        <h2>Indian Armour Systems Pvt. Ltd.</h2>
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="contact-us-left">
              <p className="d-flex align-items-center">
                <span className="address-icon">
                  <img src="/images/map.svg" />
                </span>{" "}
                <span className="address-content">
                  Universal Lane, Village Dudhola,
                  <br /> P O Bhagola Palwal, Haryana-121102, India
                </span>
              </p>
              <p className="d-flex align-items-center">
                <span className="address-icon">
                  <img src="/images/phone.svg" />
                </span>{" "}
                <span className="address-content">
                  <a href="tel:+91-9891268600">+91-9891268600,</a>
                  <br />
                  <a href="tel:+91-8750050215">+91-8750050215</a>
                </span>
              </p>
              <p className="d-flex align-items-center email-flex">
                <span className="address-icon">
                  <img src="/images/envlop.svg" />
                </span>{" "}
                <span className="address-content">
                  <a href="mailto:info@indianarmour.com">
                    info@indianarmour.com
                  </a>
                  <br />
                  <a href="mailto:prerna@indianarmour.com">
                    prerna@indianarmour.com
                  </a>
                </span>
              </p>
              {/* <div className="d-flex ">
                <span className="address-icon ">
                  <img src="/images/envlop.svg" className="invisible" />
                </span>
                <span className="address-content">
                  <div className="social-media">
                    <span>Follow Us:</span>
                    <ul className="d-flex list-inline m-0">
                    <li>
                <Link to="https://www.facebook.com/indianarmoursystems/ " target='_blank'>
                  <i className="fa-brands fa-facebook-f" />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/in/indian-armour-5b60a6188/" target='_blank'>
                  <i className="fa-brands fa-linkedin-in" />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/armour_indian" target='_blank'>
                  <i className="fa-brands fa-twitter" />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/indian_armour_systems/" target='_blank'>
                  <i className="fa-brands fa-instagram" />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UCXTbVLwSlwaSKsQoRV2E1RA" target='_blank'>
                  <i className="fa-brands fa-youtube" />
                </Link>
              </li>
                    </ul>
                  </div>
                </span>
              </div> */}


              <p className="d-flex align-items-center mt-2">
                 <span className="address-icon"><img src="images/clock-icon.svg" /></span>
                 <span className="address-content">Need a quick quote? Our sales team is online.</span>
              </p>

              <div className="d-flex align-items-center">
               
                <span className="address-icon email-d-none"><img src="images/clock-icon.svg" /></span>
                  <span className="address-content email-block"> Email us now at <a href="mailto:sales@indianarmour.com">sales@indianarmour.com</a>
                  <br />for Fast, Personalized Service.</span>
               
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="contact-us-right">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name *"
                    onInput={(e) => e.target.value = e.target.value.replace(/[^\w\s]/gi, "")}
            
                    {...register("name", { required: true })}
                  />
                  {errors.name && <span className='error'>Full Name is required</span>}
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address *"
                    {...register("email", { required: true })}
                  />
                   {errors.email && <span className='error'>Email Address is required</span>}
                </div>
                <div className=" mb-3 position-relative mobile-number">
                  {/* <span className="p-number">+91</span> */}
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile Number *"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                    {...register("mobile", {  required: false})}
                  />
                  {errors.mobile && <span className='error'>Phone Number required</span>}
                </div>

                <div className=" mb-3 position-relative country">
                  
                   <select className="form-control" {...register("country", {  required: true})}>
                   <option value="">Select Country</option>
                   {country.map((item) => (
                        
                        <option value={item.name}>{item.name}</option>
                        
                        ))}
                   
                   </select>
                 
                   {errors.country && <span className='error'>Country required</span>}
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    defaultValue={""}
                    {...register("message", { required: true })}
                  />
                   {errors.message && <span className='error'>Message is required</span>}
                </div>
                <div className="input-group justify-content-center mt-5">
                  <button className="btn know-more-btn submit">
                    Submit
                    <span className="input-group-text">
                      <img src="/images/arrow.svg" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className=" map-blk position-relative">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3515.7999046575164!2d77.27766547422252!3d28.21339130299315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cd6c5710b5431%3A0x1c95c657dfd5037e!2sIndian%20Armour%20Systems%20pvt%20ltd!5e0!3m2!1sen!2sin!4v1683610539547!5m2!1sen!2sin"
        width="100%"
        height={450}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </section>
  </>
  )
}

export default Contact