import React,{ FormEvent, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { API_URL } from "../utils/utility";
import axios from 'axios'
import { data } from "jquery";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
function BespokeSolutions() {
    const [country, setCountry] = useState([])
    const API = 'https://www.indianarmour.com/indianarmourcms/APIs/Contactus/country.php';
    const fetchPost = () => {
      fetch(API)
        .then((res) => res.json())
        .then((res) => {
          console.log(res)
          setCountry(res)
        })
    }
    useEffect(() => {
      fetchPost()
    }, [])
  
  
  
  
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        type: "message",
        body: "",
      });
  
    const SaveData = async (END_POINT , body ) => {
      console.log(END_POINT, body);
      var response = await axios.post( END_POINT, body);
      console.log(response)
      return { data: response.data, message: response.message, status: response.status || response.statusCode, errors: response.errors };
    };
  
    const { register, handleSubmit, formState: { errors }} = useForm();
    const onSubmit = async(values) => {
      
      console.log(values)
     
      try {
        let apiRes = null;
      
    
    
          apiRes = await SaveData(`${API_URL}/APIs/Contactus/index.php`, {
            ...values 
             
          });
       console.log(apiRes.status)
        if (apiRes.status == 200 && apiRes.data[0].Status == "True") {
          setInfo({ type: "message", body: "Form Submitted Successfully!" });
        //  setsubmitdisable(true)
         
        navigate(`/Thankyou`);
        } else if(apiRes.data[0].Status == "False") {
          
          setInfo({ type: "error", body: "Something went wrong. Please try again!" });
          // throw new Error(apiRes.errors);
        }
      } catch (err) {
        setInfo({ type: "error", body: err.message });
      } 
      // finally {
      //   setApiStatus({ ...apiStatus, inProgress: false });
      // }
    
    };
    return (
        <>
    <Helmet>
        <title>Customized Armor Solutions | Bespoke Body Armor by Indian Armour </title>
        <meta name="description" content="Explore our Bespoke Solutions page for military body armour at Indian Armour. Elevate your defense capabilities with tailored armour solutions crafted for precision and performance." />
        <link rel="canonical" href="https://www.indianarmour.com/bespoke-solutions" />
      </Helmet>
            <section className="inner-banner d-flex align-items-center justify-content-center" style={{ backgroundImage: 'url("/images/bespoke-solutions-banner.png")' }}>
                <div className="inner-banner-content1">
                    <div className="container">
                        <h1 className=" fw-bolder">Bespoke Solutions</h1>
                        <strong>Custom Protection, Tailored for Every Mission</strong>
                    </div>
                </div>
            </section>

            <section className="breadcrumb-inner pt-3  pb-5">
                <div className="container">
                    <nav >
                        <ol className="breadcrumb mb-0 justify-content-start">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Bespoke Solutions</li>
                        </ol>
                    </nav>
                </div>
            </section>


            <section className='page-content pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                          <p>At Indian Armour, we understand that every situation is different, especially in matters concerning safety and security. This is when our bespoke solutions come of great use as clients can customize their ballistic protection gear as per the needs of the situation in hand. Our experience in this sector over the years has made us the trusted partner for military, law enforcement, and security agencies around the world.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-pink py-70 benefits-bespoke-solution">
               <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2><strong>Why Choose Bespoke Solutions </strong></h2>
                        <p>Very often, you will find that off-the-shelf products are unable to optimally deal with the variety of challenges posed by different missions, environments, and threats. A customized solution takes into consideration the user's exact specifications and the product is tailor-made accordingly. This gives a boost to performance, comfort, and safety.
                        </p>

                        <h2 className="mt-4"><strong>Benefits of Indian Armour's Bespoke Solutions  </strong>  </h2>
                        <ul className="font-20">
                            <li><strong>Tailored Fit - </strong>One of the integral components of our bespoke solutions is ensuring the perfect fit of our ballistic protection gear. This is particularly important for body armour, helmets, and other wearable protection, where a poor fit can compromise safety.   </li>
                            <li><strong>Enhanced Performance - </strong>Customizing products to your specific requirements gives us the option to incorporate features and materials that maximize protection against the threats you are most likely to encounter. Whether it's enhanced ballistic resistance, added mobility, or specialized protection against non-ballistic threats, you can count on us when it comes to performance.   </li>
                            <li><strong>Adaptability - </strong>With the changing nature of modern-day threats, your protection gear also needs to evolve. Our bespoke solutions take into account adaptability to ensure that your gear remains relevant and effective over time.  </li>
                            <li><strong>Integration with Existing Equipment - </strong>We understand that our products need to work seamlessly with other gear and systems you already use. Our bespoke solutions are engineered to integrate perfectly with your current equipment, whether it's communication devices, weaponry, or transportation systems. </li>
                            
                        </ul>
                    </div>
                </div>
               </div>
            </section> 

            <section className="py-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4"><div className="vertical-integration"><img src="images/vertical-integration.jpg" alt="" className='img-fluid' /></div></div>
                        <div className="col-lg-8">
                            <h3><strong>Vertical Integration: Cornerstone of our Bespoke Solutions</strong> </h3>
                            <p>One of the key strengths that enable Indian Armour to deliver on our bespoke solutions is our vertically integrated production process. Vertical integration means that we control every stage of manufacturing, from sourcing raw materials to final assembly. Vertical integration makes it easy for us to customize materials and features, accelerate production, and innovate continuously. This end-to-end control ensures that each bespoke product meets the highest standards of quality and precision. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bespoke-process bg-pink  py-70">
                <div className="bespoke-heading"><div className="container"><h2><strong>The Bespoke Process</strong></h2></div></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Creating bespoke ballistic protection products at Indian Armour is a collaborative process that involves close communication between our expert
                               team and your organization. Here’s how it works:</p>
                               <ul className="bespoke-process-list font-20">
                                <li> <span><strong>Consultation </strong>- The first step that we take is to understand your specific needs, challenges, and objectives through an in-depth consultation. This is where we gather all the necessary information about the settings in which the gear will be used, the nature of the threats you might encounter, and other unique requirements </span><img src="images/besopke-process1.png" alt="" /></li>

                                <li><span><strong>Design & Development  </strong> - Based on the insights gathered during the consultation, our design team develops a tailored solution that meets your specifications. We use the latest technology and materials to create products that not only offer maximum protection but also consider factors like weight, comfort, and ease of use.</span> <img src="images/besopke-process2.png" alt="" /></li>   

                                <li><span><strong>Production and Delivery  </strong> - Once the design is approved, we move to full-scale production. Our state-of-the-art manufacturing facilities ensure that your products are produced with precision and consistency. After the final assembly of your gear, stringent testing and quality control measures are performed to make your gear field ready. We also manage logistics to ensure timely delivery of your gear.</span><img src="images/besopke-process3.png" alt="" /></li>

                                <li><span><strong>Continuous Support  </strong> - Our commitment to your safety doesn’t end with delivery. We provide ongoing continuous support to ensure that your protection gears continue to perform as expected.</span> <img src="images/besopke-process4.png" alt="" /></li>
                               </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-70  bg-black">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-white">
                            <h2 className='font-42 d-flex align-items-center justify-content-center mb-5'>Try Our Bespoke Solutions Now! <img src="/images/timely-and-reliable-delivery.png" alt="" class="ms-3 clock-img" /></h2>
                            <p className="text-white">Deviating from the one-size-fits-all approach, Indian Armour's bespoke solutions provide the customization necessary to address the unique challenges you might face. Our commitment to innovation, quality, and customer satisfaction makes us the ideal destination for tailored protection solutions. 
                            </p>
                            <p className="text-white">
                            Explore the possibilities with Indian Armour's bespoke solutions today and experience the difference that custom-designed protection gear can make.

                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-70 vi-contact" style={{ backgroundImage: 'url("/images/vertical-integration-contact.jpg")' }}>
        <div className="container">
          <h2 className="font-42"><strong>Contact Us Now</strong></h2>
          <div className="row">
            <div className="col-lg-6">
              <img src="/images/vi-contact-img.png" alt="" className="img-fluid"/>
            </div>
            <div className="col-lg-6">
              <div className="contact-us-right">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name *"
                    onInput={(e) => e.target.value = e.target.value.replace(/[^\w\s]/gi, "")}
            
                    {...register("name", { required: true })}
                  />
                  {errors.name && <span className='error'>Full Name is required</span>}
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address *"
                    {...register("email", { required: true })}
                  />
                   {errors.email && <span className='error'>Email Address is required</span>}
                </div>
                <div className=" mb-3 position-relative mobile-number">
                  {/* <span className="p-number">+91</span> */}
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile Number *"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                    {...register("mobile", {  required: false})}
                  />
                  {errors.mobile && <span className='error'>Phone Number required</span>}
                </div>

                <div className=" mb-3 position-relative country">
                  
                   <select className="form-control" {...register("country", {  required: true})}>
                   <option value="">Select Country</option>
                   {country.map((item) => (
                        
                        <option value={item.name}>{item.name}</option>
                        
                        ))}
                   
                   </select>
                 
                   {errors.country && <span className='error'>Country required</span>}
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    defaultValue={""}
                    {...register("message", { required: true })}
                  />
                   {errors.message && <span className='error'>Message is required</span>}
                </div>
                <div className="input-group justify-content-center mt-5">
                  <button className="btn know-more-btn submit">
                    Submit
                    <span className="input-group-text">
                      <img src="/images/arrow.svg" />
                    </span>
                  </button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}

export default BespokeSolutions