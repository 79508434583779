import React from 'react'
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
function TabData() {

    
  const location = useLocation(); 
  const slug = location.pathname.split("/")[2];

    const [post, setPost] = useState([]);
  const API = `https://www.indianarmour.com/indianarmourcms/APIs/Product/producttab.php?page=${slug}`;
  const ImageURL = 'https://www.indianarmour.com/indianarmourcms/';

 
  const fetchPost = () => {
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setPost(res)
      })
  }
  useEffect(() => {
    fetchPost()
  }, []);

 
  return (
    <>
    

    <section className="armed-vehicle right-black-bg">
          <div className="container">
          <div className="armed-tab">

            

              <ul className="nav nav2 nav-pills mb-3" id="pills-tab" role="tablist">
             
              {post.map((item, i) => (
                <>

                  <li className="nav-item" role="presentation">
                  <button className={`nav-link ${i===0 ? "active" : ""}`} id={"vest" + i} data-bs-toggle="pill" data-bs-target={"#Vest-detail" + i} type="button" role="tab" aria-controls="pills-home" aria-selected="true">{item?.tab_name}</button>
                </li>
                
                </>
                
                ))}

               
  
              </ul>

              <div className="tab-content tab-content2" id="pills-tabContent">

              {post.map((item, i) => (
                <>
                <div className={`tab-pane fade show ${i===0 ? "active" : ""}`} id={"Vest-detail" + i} role="tabpanel" aria-labelledby={"#vest" + i} tabIndex={0}>
                  <div className="Vest-description-p">
                  <div
      dangerouslySetInnerHTML={{__html: item?.content}}
    />
                  </div>
                  {i===0 ? 
                  
                  <div className="vest-description">
                    
                    <div className="ballistic-vesta-box">
                    <Link to="/bespoke-solutions" >
                 
                    <ul>
                    <li className="vesta-box"><img src={ImageURL + item?.tabimage[1]?.image} /></li>
                    <li  dangerouslySetInnerHTML={{__html: item?.tabimage[1]?.heading}}></li>
                    </ul>
                    </Link>
                    </div>
                  
                  
                    <div className="ballistic-vesta-box">
                    <Link to="/ballistic-products-customization-bulk-ordering" >
                    <ul>
                    <li className="vesta-box"><img src={ImageURL + item?.tabimage[2]?.image} /></li>
                    <li  dangerouslySetInnerHTML={{__html: item?.tabimage[2]?.heading}}></li>
                    </ul>
                    </Link>
                    </div>
                   

                    <div className="ballistic-vesta-box">
                    <Link to="/vertical-integration" >
                    <ul>
                    <li className="vesta-box"><img src={ImageURL + item?.tabimage[3]?.image} /></li>                   
                    {/* <li  dangerouslySetInnerHTML={{__html: item?.tabimage[3]?.heading}}></li> */}
                    <li>  Vertical <br /> Integration</li>
                  
                    </ul>
                    </Link>
                    </div>

                    <div className="ballistic-vesta-box">
                    <ul>
                    <li className="vesta-box"><img src={ImageURL + item?.tabimage[4]?.image} /></li>
                    <li  dangerouslySetInnerHTML={{__html: item?.tabimage[4]?.heading}}></li>
                    </ul>
                    </div>
                  
              
             
                </div>
                  
                  
                  : 
                  
                  ""}
               

                </div>
                </>

               ))}


              
              </div>
              
          </div>


              </div>
              </section>
    </>
  )
}

export default TabData