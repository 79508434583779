import React,{ FormEvent, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { API_URL } from "../utils/utility";
import axios from 'axios'
import { data } from "jquery";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
function VerticalIntegration() {
  const [country, setCountry] = useState([])
  const API = 'https://www.indianarmour.com/indianarmourcms/APIs/Contactus/country.php';
  const fetchPost = () => {
    fetch(API)
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setCountry(res)
      })
  }
  useEffect(() => {
    fetchPost()
  }, [])




  const navigate = useNavigate();
  const [info, setInfo] = useState({
      type: "message",
      body: "",
    });

  const SaveData = async (END_POINT , body ) => {
    console.log(END_POINT, body);
    var response = await axios.post( END_POINT, body);
    console.log(response)
    return { data: response.data, message: response.message, status: response.status || response.statusCode, errors: response.errors };
  };

  const { register, handleSubmit, formState: { errors }} = useForm();
  const onSubmit = async(values) => {
    
    console.log(values)
    // this.preventDefault();
    // if (!checked) {
    //   return setInfo({
    //     body: "Please accept the terms and condition",
    //     type: "error",
    //   });
    // } else if (!checkedPrivacyPolicy) {
    //   return setInfo({
    //     body: "Please accept the Privacy Policy",
    //     type: "error",
    //   });
    // }
    // setInfo({ body: "", type: "message" });
     
  
  
  
  
  
    
    try {
      let apiRes = null;
    
  
  
        apiRes = await SaveData(`${API_URL}/APIs/Contactus/index.php`, {
          ...values 
           
        });
     console.log(apiRes.status)
      if (apiRes.status == 200 && apiRes.data[0].Status == "True") {
        setInfo({ type: "message", body: "Form Submitted Successfully!" });
      //  setsubmitdisable(true)
       
      navigate(`/Thankyou`);
      } else if(apiRes.data[0].Status == "False") {
        
        setInfo({ type: "error", body: "Something went wrong. Please try again!" });
        // throw new Error(apiRes.errors);
      }
    } catch (err) {
      setInfo({ type: "error", body: err.message });
    } 
    // finally {
    //   setApiStatus({ ...apiStatus, inProgress: false });
    // }
  
  };
  return (
    <>
      <section className="inner-banner2 " style={{ backgroundImage: 'url("/images/vertical-ntegration-banner.jpg")' }}>
        <div className="inner-banner2-content d-flex align-items-center">
          <div className="container">
            <h1 className=" fw-bolder">Vertical Integration</h1>
            <strong>From Yarn to Finished Products</strong>
          </div>
        </div>
      </section>
      <section className="breadcrumb-inner pt-3  pb-5 ">
        <div className="container">
          <nav >
            <ol className="breadcrumb mb-0 justify-content-start">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Vertical Integration</li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="pb-70 pt-4 mobile-mt-0">
        <div className="container">
          <div className="row">
            <h2>What makes Indian Armour a leading manufacturer in the defence and security industry?</h2>
            <p>One of the key strategies driving our success is vertical integration. This makes it possible for us to enhance production
              efficiency, quality control, and overall product excellence. </p>
            <p>By vertically integrating our production process, we control every aspect of manufacturing ensuring that we meet and
              exceed the expectations of our customers</p>
          </div>
        </div>
      </section>

      <section className="bg-pink py-70">
        <div className="container">
          <div className="row">
            <h2>Total Control Over Production</h2>
            <p>Vertical integration implies total control over the production process, from raw material procurement to the final product
              assembly.</p>

            <h3 className='mt-4'><strong>More Control for More Efficiency:</strong></h3>
            <ul className='font-20 list-inside'>
              <li>Ensures consistent supply of materials by reducing our dependence on external suppliers and mitigates risks associated
                with supply chain disruptions.</li>
              <li>Helps us closely monitor and manage production standards, ensuring that every product meets our stringent quality
                requirements</li>
              <li>Leads to increased efficiency as it streamlines operations, reduces waste, and optimizes resource allocation.</li>
              <li>Enhances our ability to respond to changing market demands as we can adjust production schedules and processes
                without the delays associated with external dependencies.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="py-70 advance-rd-block">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-4 col-md-4">
              <img src="/images/advanced-r-&-d-capabilities.jpg" alt="Advanced R&D Capabilities" className='img-fluid' />
            </div>
            <div className="col-lg-8 col-md-8">
              <h2><strong>Advanced R&D Capabilities</strong></h2>
              <p>We have an in-house Research and Development team that thrives on
                innovation to keep our products updated with the latest technological
                advancements. The vertical integration process ensures there is perfect
                coordination between our R&D and Manufacturing teams.</p>
              <h4 className='mt-4'><strong>To Stay Ahead of the Rest:</strong></h4>
              <ul className="font-20">
                <li>Innovative ideas are seamlessly transformed into high-quality products.</li>
                <li>Rapid prototyping and testing, enable us to bring new products to market
                  faster and respond to emerging threats and requirements with agility.</li>
                <li>Continuous improvement in existing products, incorporating the latest
                  advancements and feedback from the field.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className='py-70 bg-pink customized-soulution-blk'>
        <div className="container">
          <div className="row d-flex align-items-center ">
            <div className="col-lg-8 col-md-8">
              <h2><strong>Customized Solutions</strong></h2>
              <p>Whether you need tailored sizing and colours, additional features, or
                customized accessories, we have got you covered. Also, the option to tailor
                your personal protection gear extends to a wide range of products.
                Consultation with clients is also thoroughly inculcated in our bespoke services.</p>
              <h4 className='mt-4'><strong>Meeting Your Unique Needs with Accuracy:</strong></h4>
              <ul className="font-20">
                <li>Capacity to swiftly adapt our production processes to meet unique
                  requirements</li>
                <li>Catering to diverse applications in the defence, law enforcement, and
                  security sectors, where standard products may not always meet unique
                  operational needs.</li>
                <li>Preferred partner for organizations requiring tailored protective gear and
                  equipment.</li>
                <li>Expertise in creating solutions that address the precise challenges of clients,
                  improving their operational effectiveness and safety</li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4">
              <img src="/images/customized-solutions.jpg" alt="" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      <section className="py-70 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-center">Stringent Quality Assurance</h2>
              <p>Every product undergoes rigorous testing and quality assurance protocols to be compliant with standards set by
                international organizations such as the National Institute of Justice (NIJ). Our vertically integrated production process allows
                for consistent monitoring and evaluation of every step involved in the process.</p>

                <h4><strong>Quality is our Guarantee:</strong></h4>
                <ul className="font-20">
                  <li>Comprehensive quality control process, including inspection, testing, and validation at every stage.</li>
                  <li>Maintaining consistency and reliability across all product lines for the best possible protection.</li>
                  <li>Advanced testing facilities and protocols to simulate real-world conditions, ensuring that our products perform as
                  expected in the field.</li>
                  <li>Compliance with international standards and certifications establishes confidence in clients in the reliability of our
                  products.</li>
                </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="py-70 bg-black">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-white">
              <h2 className="font-42 d-flex align-items-center justify-content-center mb-5">Timely and Reliable Delivery <img src="/images/timely-and-reliable-delivery.png" alt="" className='ms-3 clock-img'/></h2>
              <p className='text-white'>Regardless of the size of your order or the type of customization opted for, Indian Armour utilizes its seamless logistics
              system to deliver your order on time. Your orders are handled with care at every step of the supply chain.</p>
              <h4><strong>Speed is the Name of the Game:</strong></h4>
              <ul className="font-20">
                <li>Meeting the demand of clients who rely on consistent supply for their operations.</li>
                <li>Reduction in lead time by controlling the entire production and logistics process, we can reduce lead times.</li>
                <li>Inbuilt capacity to handle both standard and urgent orders, which is vital for clients who often operate in high-threat
                environments.</li>
                <li>Catering to bulk orders, making us an ideal destination for White-label product dealers.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="py-70 vi-contact" style={{ backgroundImage: 'url("/images/vertical-integration-contact.jpg")' }}>
        <div className="container">
          <h2 className="font-42"><strong>Contact Us Now</strong></h2>
          <div className="row">
            <div className="col-lg-6">
              <img src="/images/vi-contact-img.png" alt="" className="img-fluid"/>
            </div>
            <div className="col-lg-6">
              <div className="contact-us-right">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name *"
                    onInput={(e) => e.target.value = e.target.value.replace(/[^\w\s]/gi, "")}
            
                    {...register("name", { required: true })}
                  />
                  {errors.name && <span className='error'>Full Name is required</span>}
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address *"
                    {...register("email", { required: true })}
                  />
                   {errors.email && <span className='error'>Email Address is required</span>}
                </div>
                <div className=" mb-3 position-relative mobile-number">
                  {/* <span className="p-number">+91</span> */}
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile Number *"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                    {...register("mobile", {  required: false})}
                  />
                  {errors.mobile && <span className='error'>Phone Number required</span>}
                </div>

                <div className=" mb-3 position-relative country">
                  
                   <select className="form-control" {...register("country", {  required: true})}>
                   <option value="">Select Country</option>
                   {country.map((item) => (
                        
                        <option value={item.name}>{item.name}</option>
                        
                        ))}
                   
                   </select>
                 
                   {errors.country && <span className='error'>Country required</span>}
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    defaultValue={""}
                    {...register("message", { required: true })}
                  />
                   {errors.message && <span className='error'>Message is required</span>}
                </div>
                <div className="input-group justify-content-center mt-5">
                  <button className="btn know-more-btn submit">
                    Submit
                    <span className="input-group-text">
                      <img src="/images/arrow.svg" />
                    </span>
                  </button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default VerticalIntegration